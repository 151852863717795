<template>
  <HandleDataForms v-bind:apiSource="mongo" v-bind:dataSource="'/' + database" title="Formulario Prospecto"
    collection="eloqua_prospects" templateTable="TableProspectosForm" templateTableModal="TableProspectosModal"
    v-bind:dataExcel="dataExcel" />
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import HandleDataForms from '@/components/HandleDataForms.vue'

export default {
  components: {
    HandleDataForms,
  },
  setup() {
    const store = useStore()
    const mongo = computed(() => store.getters.getMongoURL)
    const database = computed(() => store.getters.getDBDashboard)
    const startDate = computed(() => moment().startOf('week').format('YYYY-MM-DD'))
    const endDate = computed(() => moment().format('YYYY-MM-DD'))

    let dataExcel = {
      fields: {
        createdAt: 'Fecha',
        RUToRUTProvisionalDeContacto_c: 'Número de documento',
        DNIoPasaporte_c: 'DNI o Pasaporte',
        PrimaryContactPersonFirstName: 'Nombre',
        PrimaryContactPersonLastName: 'Apellido Paterno',
        ApellidoMaterno_c: 'Apellido Materno',
        PrimaryContactEmailAddress: 'Email',
        PrimaryPhoneAreaCode: 'Código de área',
        PrimaryPhoneNumber: 'Número de teléfono',
        PrimaryPhoneCountryCode: 'Código del país',
        TipoDeTelefono_c: 'Tipo de Teléfono',
        TipoDeCorreo_c: 'Tipo de Correo',
        TipoDeLead_c: 'Tipo de Lead',
        ChannelType: 'Tipo de Canal',
        TipoDeOrigen_c: 'Tipo de Origen',
        Nacionalidad_c: 'Nacionalidad',
        PaisDeOrigen_c: 'País de Origen',
        UnidadDeNegocio_c: 'Unidad de Negocio',
        FormularioDeOrigen_c: 'Formulario de Origen',
        ComunaColegio_c: 'Comuna del Colegio',
        RegionColegio_c: 'Región del Colegio',
        RBDoIdentificadorDeInstitucion_c: 'Identificador de la Institución',
        AnioEstimadoDeEgresoTexto_c: 'Año Estimado de Egreso',
        device: 'Dispositivo',
        utm_medium: 'Medio de la Campaña',
        utm_source: 'Fuente de la Campaña',
        utm_campaign: 'Nombre de la Campaña',
        CodigoDEMREDePrograma_c: 'Código DEMRE del Programa',
        Institucion_c: 'Institución',
        TipoDePrograma_c: 'Tipo de Programa',
        Modalidad_c: 'Modalidad',
        Jornada_c: 'Jornada',
        Name: 'Nombre de la Carrera',
        Matricula: 'Matrícula',
        Arancel: 'Arancel',
        Carrera_c: 'Código de la Carrera',
        Sede_c_name: 'Sede',
        ZonaDeAdmision_c: 'Zona de Admisión',
      },
      query: {
        collection: 'eloqua_prospects',
        date_field: 'createdAt',
        fecha_inicio: startDate.value + ' 00:00:00',
        fecha_fin: endDate.value + ' 23:59:59',
      },
    }

    return {
      mongo,
      dataExcel,
      database
    }
  },
}
</script>
